<template>    
        <s-crud
        add
        edit
        remove
        title="Artículos"  
        @save="save($event)"
        :config="config"
        :filter="filter"
        search-input
        ref="crud"
        >  
        <template slot-scope="props">
            <v-col>
                <v-row justify="center">
                    <v-col class="s-col-form" sm="6" md="6" lg="6">
                        <s-select-articles
                        label="Artículos**"
                        :text="props.item.ArtDescription"
                        v-model="ArtID"
                        full
                        >                           
                        </s-select-articles>
                    </v-col>
                    <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
                        <s-text v-model="props.item.ArtUnitMeasure" disabled label="Unidad de Medida" > </s-text> 
                    </v-col>                    
                    <v-col class="s-col-form" sm="3" md="3" lg="3">
                        <s-select-definition v-model="props.item.ArtStatus" :def="1152" label="Estado">                            
                        </s-select-definition>
                    </v-col>                    
                </v-row>
            </v-col>
        </template>
        <template v-slot:ArtStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.ArtStatus == 1 ? 'success' : 'error'">
          {{ row.ArtStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
      <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
          {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
        </s-crud>
    
</template>

<script >

import SSelectArticles from '../../../components/logistics/SSelectArticlesCategory.vue';
import _sArticleS  from "@/services/Logistics/ArticleAreaService";
//import _sArticleS  from "@/services/Logistics/ArticlesService";
export default {
    name: "LogisticsArticles",
    components: {SSelectArticles},
    props: {
        AreasID: { type: Number, default: 0 },
        //addNoFuncion: { type: Boolean , default: false },
    },
    data() {
        return {
            dialogEdit: false,
         //   ArtID: 0,
        };
    },
    computed: {
        config() {
            return{
                filter:{ AreID: this.AreasID } ,
                model: {
                    ArtAreID: "ID",                    
                    ArtDescription:"strin",
                    ArtUnitMeasure:"string",
                    ArtStatus: "int"
                },
                service: _sArticleS,
                headers: this.$fun.getSecurity().IsLevelAdmin
                ? [
                    { text: "ID" , value: "ArtAreID" ,  sortable: false },
                    { text: "Descripción" , value: "ArtDescription" , sortable: false },
                    { text: "Unidad M." , value: "ArtUnitMeasure" , sortable: false },
                    { text: "Estado" , value: "ArtStatus" , sortable: false },
                ]
                : [
                    { text: "ID" , value: "ArtAreID" ,  sortable: false },
                    { text: "Descripción" , value: "ArtDescription" , sortable: false },
                    { text: "Unidad M." , value: "ArtUnitMeasure" , sortable: false },
                    { text: "Estado" , value: "ArtStatus" , sortable: false },
                ],
            };
        },
        isAdmin(){
            this.$fun.getSecurity().IsLevelAdmin;
        },
    },
    methods: {
        Inicialize() {            
            this.filter = {AreID: this.AreasID};
        },
        edit(item){
            this.dialogEdit = true;
        },
        /* rowSelected(rows) {
            if (rows.length > 0) {
                this.$emit("rowSelected", rows);
            }
        }, */
        save(item) {
            /* item.ArtID = this.ArtID;
            item.AreID = this.AreasID; */
            item.SecStatus = 1;
            if (item.ArtDescription == 0) {
                this.$fun.alert("Digite y seleccione un articulo", "warning");
                return;
            }
            if (this.validationForm(item));
                console.log(item);
                item.save();            
        },

        validationForm(item){
            return true;
        },        
    }, 
    watch: {
        AreasID() {
            this.Inicialize();
        },
    },
    created() {
        this.filter = {AreID: this.AreasID};
    },
    
};
</script>
