<template>
    <div>
        <v-row>
            <v-col lg="12">
                <logistics-area  :isAdmin="true"  @rowSelected="rowSelected($event)"  >
                </logistics-area>                                          
            </v-col>
            <v-col lg="12">
                <logistics-articles :isAdmin="true"   :AreasID="AreasID"  ></logistics-articles>
            </v-col>                      
        </v-row>
    </div>
</template>
<script>
import LogisticsArea from "./Area";
import LogisticsArticles from "./Articles";

export default {
    components: {LogisticsArea, LogisticsArticles},
    data() {
        return {            
            AreasID: 0,           
        };
    },
    Areasdb() {
        return {
            parentID: 0,
            AreasID: 0,
        };
    }, 
    methods: {
        rowSelected(rows) { 
            if(rows.length>0){
                
                this.AreasID = rows[0].AreID; 
            }                   
        },
    },
   
};
</script>