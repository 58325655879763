import Service from "../Service";
const resource = "articlearea/";

export default {
    save(art, requestID) {
        return Service.post(resource + "save", art, { params: { requestID: requestID }, });
    },  
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
          params: { requestID: requestID },
        });
      },
};
 