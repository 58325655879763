<template>
    <v-col>
        <s-crud        
        height="260"
        :filter="filter"        
        title="Area"        
        @rowSelected="rowSelected($event)" 
        :config="config"
        search-input
        >
            <template v-slot:filter>
                <v-col>
                    <v-row justify="center">
                        <v-col lg="6" class="s-col-form">
                            <s-select-definition
                            clearable
                            v-model="filter.TypeArea"
                            :def="1119"
                            label="Nivel del Área"
                            >
                            </s-select-definition>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template slot-scope="props">
                <v-col>
                    <v-row justify="center">
                        <v-col class="s-col-form">
                            <s-text v-model="props.item.AreaName" label="Nombre">                                
                            </s-text>
                        </v-col>
                        <v-col class="s-col-form">
                            <s-text v-model="props.item.AreAbbreviation" label="Abreviatura">                                
                            </s-text>                            
                        </v-col>                        
                    </v-row>
                    <v-row justify="center">
                        <v-col class="s-col-form">
                            <s-text v-model="props.item.AreAcronym" label="Siglas">                                
                            </s-text>
                        </v-col>
                        <v-col class="s-col-form">
                            <s-select-definition 
                            v-model="props.item.TypeArea" 
                            label="Nivel de Área" 
                            :def="1119"
                            >                                
                            </s-select-definition>
                        </v-col>
                        <v-col class="s-col-form">
                            <s-select-area
                            clearable
                            :TypeArea="props.item.TypeArea == 1 ? 10 : props.item.TypeArea - 1"
                            v-model="props.item.AreParentID"
                            :text="props.item.AreParentName"                            
                            label="Área Superior"
                            >                                
                            </s-select-area>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col class="s-col-form">
                            <s-select-position
                            clearable
                            v-model="props.item.PstBossID"                            
                            :text="props.item.ManagerName"
                            label="Jefatura"
                            >                                
                            </s-select-position>
                            <v-col class="s-col-form">
                                <s-select-position
                                clearable
                                v-model="props.item.PstManagerID"
                                :text="props.item.BossName"                                
                                label="Gerencia"
                                >                                    
                                </s-select-position>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </s-crud>
    </v-col>
</template>

<script >
import _sArea from "@/services/General/AreaService";
import SText from '../../../components/Utils/SText.vue';
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';
import SSelectPosition from '../../../components/Utils/sSelectPosition.vue';
export default {
    name: "LogisticsArea",
    components: {SSelectPosition,SSelectDefinition ,SText },
    props: {},

    
  data: ()=> ({
      filter:{ TypeArea: 0 , AreID: 0 },
      config:{ 
          model: {
              AreID: "ID",
              PstManagerID: "int",
          },
          service: _sArea,
          headers: [
              { text: "ID" ,  value: "AreID" , sortable: false , width: 10 },
              { text: "Área" ,  value: "AreName" , sortable: false },
              { text: "Abreviatura" , value: "AreAbbreviation" , sortable: false },
              { text: "Tipo" , value: "TypeAreName" , sortable: false },
              { text: "Gerencia" ,  value: "ManagerName" , sortable: false },
              { text: "Jefatura" , value: "BossName" , sortable: false },
          ],
      },
  }),
  methods: {
      /* save(item) {
          if (item.AreName.lenght < 1) {
              this.$fun.alert("Ingrese un nombre al Área", "warning");
              return;
          }
          item.save();
      }, */
      rowSelected(items) {          
        this.$emit("rowSelected", items);  
             
    },
      
  },    
}
</script>
